(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("dialog", [], factory);
	else if(typeof exports === 'object')
		exports["dialog"] = factory();
	else
		root["FDV"] = root["FDV"] || {}, root["FDV"]["dialog"] = factory();
})(window, function() {
return 